<template>
  <div class="address-page">
    <ul v-if="list.length > 0" class="address-list">
      <template v-for="(item, index) in list" :key="index">
        <li class="item">
          <div class="info">
            <span class="area">
              {{ item.BankName }}
              <label :class="'status status_' + item.Status">{{
                item.Status == 1
                  ? PAGELANG.已通过
                  : item.Status == 2
                  ? PAGELANG.已驳回
                  : PAGELANG.待审核
              }}</label>
            </span>
            <span class="detail">{{ item.Account }}</span>
            <span class="phone">{{ item.AccountName }}</span>
            <span v-if="item.Status == 2" class="reason"
              >{{ PAGELANG.驳回原因 }}{{ item.Reason }}</span
            >
          </div>
          <div class="control">
            <div class="btns">
              <van-button
                type="default"
                plain
                size="small"
                @click="onDelete(item.ID)"
                >{{ SYSLANG.delete }}</van-button
              >
              <van-button
                type="default"
                plain
                size="small"
                :to="'/income/account/edit?accountid=' + item.ID"
                >{{ SYSLANG.edit }}</van-button
              >
            </div>
          </div>
        </li>
      </template>
    </ul>
    <div
      v-else
      style="
        color: var(--van-list-text-color);
        font-size: var(--van-list-text-font-size);
        line-height: var(--van-list-text-line-height);
        text-align: center;
      "
    >
      {{ PAGELANG.暂时没有提现账户 }}
    </div>

    <div class="add-btn-block">
      <van-button
        type="primary"
        icon="aog1 iconfont icon-add"
        block
        color="var(--van-card-price-color)"
        to="/income/account/edit"
        >{{ PAGELANG.添加账户 }}</van-button
      >
    </div>
  </div>

  <div class="pc-my-page">
    <MyMenu :agentInfo="agentInfo" pagename="income" />
    <div class="main">
      <div
        style="
          display: flex;
          width: 100;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
        "
      >
        <div class="big-title" style="margin-bottom: 0">
          {{ PAGELANG.提现账户 }}
        </div>
        <el-button color="#000" @click="editAccount()">{{
          PAGELANG.添加账户
        }}</el-button>
      </div>
      <el-table
        :data="list"
        class="logs"
        size="large"
        :empty-text="SYSLANG.finished"
      >
        <el-table-column prop="Account" :label="PAGELANG.收款账户" />
        <el-table-column prop="BankName" :label="PAGELANG.开户行" />
        <el-table-column
          prop="AccountName"
          :label="PAGELANG.开户人姓名"
          width="120"
        />
        <el-table-column prop="Status" :label="SYSLANG.状态" width="80">
          <template #default="scope">
            <label :class="'status status_' + scope.row.Status">
              {{
                scope.row.Status == 1
                  ? PAGELANG.已通过
                  : scope.row.Status == 2
                  ? PAGELANG.已驳回
                  : PAGELANG.待审核
              }}
            </label>
          </template>
        </el-table-column>
        <el-table-column prop="Reason" :label="PAGELANG.驳回原因2" width="160">
          <template #default="scope">
            <span v-if="scope.row.Reason" style="color: #f14b6d">{{
              scope.row.Reason
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ID"
          :label="CARTLANG.操作"
          width="160"
          align="right"
        >
          <template #default="scope">
            <el-button type="danger" link @click="onDelete(scope.row.ID)">
              {{ SYSLANG.delete }}
            </el-button>
            <el-button link @click="editAccount(scope.row.ID)">
              {{ SYSLANG.edit }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      v-model="showEditAccount"
      :title="accountid > 0 ? PAGELANG.编辑账户 : PAGELANG.添加账户"
      width="30%"
      class="edit-account"
      z-index="100"
    >
      <el-form
        :model="account"
        label-width="120"
        :rules="rules"
        ref="accountForm"
      >
        <el-form-item :label="PAGELANG.收款账户" prop="Account">
          <el-input
            v-model="account.Account"
            :placeholder="PAGELANG.请输入收款账户"
            :validate-event="false"
            autocomplete="off"
            size="large"
          />
        </el-form-item>
        <el-form-item :label="PAGELANG.开户行" prop="BankName">
          <el-input
            v-model="account.BankName"
            :placeholder="PAGELANG.请输入开户行"
            :validate-event="false"
            autocomplete="off"
            size="large"
          />
        </el-form-item>
        <el-form-item :label="PAGELANG.开户人姓名" prop="AccountName">
          <el-input
            v-model="account.AccountName"
            :placeholder="PAGELANG.请输入开户人姓名"
            :validate-event="false"
            autocomplete="off"
            size="large"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showEditAccount = false">
            {{ SYSLANG.quxiao }}
          </el-button>
          <el-button type="primary" @click="saveAccount()" color="#000">
            {{ SYSLANG.save }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  showToast,
  showConfirmDialog,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      list: [],
      agentInfo: {},
      accountid: 0,
      showEditAccount: false,
      account: {},
      rules: {},
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.income;
    this.CARTLANG = this.LANG.pages.cart;

    this.rules = {
      Account: [{ required: true, message: this.PAGELANG.收款账户不能为空 }],
      BankName: [{ required: true, message: this.PAGELANG.开户行不能为空 }],
      AccountName: [
        { required: true, message: this.PAGELANG.开户人姓名不能为空 },
      ],
    };

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.credit)
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo } = data;
          console.log(code, msg);
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;
            this.init();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.IncomeAccount.getMemberInfo.error", error);
          this.getMemberInfo();
        });
    },
    init() {
      this.axios.get(this.actions.income_account).then(({ data }) => {
        closeToast();
        let { code, msg, list } = data;
        if (code == "SUCCESS") {
          this.list = list;
        } else {
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      });
    },
    onDelete(addrid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要删除该收款账户吗,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.income_account_delete + addrid)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.$router.go(0);
                  }
                },
              });
            })
            .catch((error) => {
              closeToast();
              console.log("views.IncomeAccount.onDelete.error", error);
            });
        })
        .catch(() => {});
    },

    editAccount(accid = 0) {
      this.accountid = accid;
      this.showEditAccount = true;

      if (!isNaN(this.accountid) && this.accountid > 0) {
        this.axios
          .get(this.actions.income_account_detail + this.accountid)
          .then(({ data }) => {
            closeToast();
            let { code, msg, detail } = data;
            console.log(code, msg, detail);
            if (code != "SUCCESS") {
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "NOTLOGIN") {
                    this.$router.replace({
                      path: "/login",
                    });
                  }
                },
              });
            } else if (detail) {
              this.account = detail;
            } else {
              this.accountid = 0;
            }
          })
          .catch((error) => {
            closeToast();
            console.log("views.IncomeAccount.editAccount.error", error);
            this.editAccount(accid);
          });
      } else {
        this.accountid = 0;
        this.account = {};
      }
    },

    saveAccount() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          this.account.accountid = this.accountid;

          showLoadingToast({
            message: this.SYSLANG.正在保存,
            duration: 0,
            forbidClick: true,
          });

          this.axios
            .post(this.actions.income_account_save, this.account)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.showEditAccount = false;
                    this.init();
                  }
                },
              });
            })
            .catch((error) => {
              closeToast();
              console.log("views.IncomeAccount.saveAccount.error", error);
              this.saveAccount();
            });
        }
      });
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style src="../assets/css/addresslist.css" scoped></style>
<style scoped>
.address-list .item .control {
  justify-content: flex-end;
}

.address-list .item .info .area {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.address-list .item .info .area .status.status_0,
.pc-my-page .logs .status.status_0,
.address-list .item .info .reason {
  color: #f14b6d;
}
.address-list .item .info .area .status.status_1,
.pc-my-page .logs .status.status_1 {
  color: green;
}

.address-list .item .info .area .status.status_2,
.pc-my-page .logs .status.status_2 {
  color: #f00;
}
</style>